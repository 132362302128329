import React, { useState, useEffect } from "react";

import ProductListDescription from "src/containers/ProductListDescription";
import { Container } from "src/templates/styles";

const queries = require("../api/queries");
const sanity = require("../api/sanity");

export interface CollectionsProps {}

const collectionQuery = sanity.fetch(queries.collections);

const Collections = () => {
  const [collections, setCollections] = useState([]);
  const [products, setProducts] = useState([]);
  const [isCollection, setIsCollection] = useState(false);

  useEffect(() => {
    !collections?.length && collectionQuery.then((res: any) => {
      setCollections(res);
    });
  });

  useEffect(() => {
    const items = [];
    collections?.length && collections.map((collection, idx) => {
      const item = collection?.slug !== "all" ? collection?.modules[0]?.products[0] : collection?.modules[0]?.products[0].product
      item.content.main.title = collections[idx]?.content?.main?.title
      item.content.main.imageCover = collection?.content?.main?.imageCover
      items.push({product: item})
      setIsCollection(true)
    })
    setProducts(items);
  }, [collections]);


  return collections?.length && products?.length && (
    <Container
      notificationBar={false}
    >
      <ProductListDescription
        data= {{
          button: { enabled: false, link: "", text: "" },
          heading: null,
          products: products,
          firstLarge: false,
          showAddToCart: false,
          showPrice: false,
          showRating: false,
          isCollection: isCollection
        }}
      />
    </Container>
  );
};

export default Collections;
